import { InputSelect } from '~/components/NovosInputs';
import ToggleDefault from '~/components/ToggleDefault';
import useAuth from '~/hooks/useAuth';
import api from '~/services/api';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  Accordion,
  Button,
  Dropdown,
  Spinner,
  SplitButton,
} from 'react-bootstrap';
import { BsLightningFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { CustomSeparator } from '../../Components';
import {
  alteraStatusdoXml,
  formataItensParaTabela,
  validaItensDoXML,
} from '../../Funcoes/Validacao';
import { PerfilData, ProductDataToTable } from '../../protocols';
import { useValidadorXML } from '../../ValidadorContext';
import {
  formatCeanAndCeanTrib,
  getPluAndGtin,
  isFornNotRegisteredOrInactive,
  isNFProcessede,
  isNFValidated,
  isNotaFiscalCanceled,
  isTipoStatusManifestoNotValid,
} from '../../validations/validationFunctions';
import { corrigirArrayDeObjetos } from '../../Funcoes/Validacao/corrigirArrayDeObjetos';
import { SectionParams } from '../../styles';

const MySwal = withReactContent(Swal);

export const ParametrosDeValidacao: React.FC = () => {
  const { user } = useAuth();
  const [showStatusValidacao, setShowStatusValidacao] = useState<string>('1');
  const [refetchPerfil, setRefetchPerfil] = useState(false);

  const {
    showParams,
    setShowParams,
    setShowCapa,
    parametrosValidacao,
    setParametrosValidacao,
    perfis,
    setPerfis,
    dadosDaCapa,
    setDadosDaCapa,
    setIsValidXML,
    setLoading,
    loading,
    setShowTotais,
    dadosDoXML,
    dadosErpDoFornecedor,
    setDadosErpDoFornecedor,
    setItensDaTabela,
    setShowXMLData,
    setShowTable,
    statusValidacao,
    setStatusValidacao,
    setCamposAlterados,
    hasFornecedor,
    tipoDoXML,
    onSetToastShown,
    onSetCounterValida,
    formParamsValidacao: { register, control, setValue, formState, getValues },
  } = useValidadorXML();

  const itemsValidation = useCallback(
    async (showLog?: boolean, nfValidada?: boolean) => {
      const newItem: any = [];
      try {
        if (dadosDoXML) {
          /**
           * Valida Fornecedor
           */
          if (showLog) {
            setStatusValidacao((prev) => [...prev, '[ITENS]']);
          }

          const itensForPromise = dadosDoXML?.itens[0].map(
            async (item: any, i: any) => {
              if (showLog) {
                setStatusValidacao((prev) => [
                  ...prev,
                  `[${item.cEAN} - ${item.xProd}]`,
                ]);
              }

              /**
               * Formata GTIN
               */
              if (showLog) {
                setStatusValidacao((prev) => [...prev, '- Formatando GTIN']);
              }
              const formatedGtin = await formatCeanAndCeanTrib(item);

              /**
               * Verifica se o item existe no ERP
               */
              if (showLog) {
                setStatusValidacao((prev) => [
                  ...prev,
                  '- Buscando item no ERP.',
                ]);
              }
              const productCodeAndGtin = await getPluAndGtin(
                formatedGtin, // gtin do xml
                Number(dadosErpDoFornecedor.cod_fornecedor.value), // código do fornecedor
                item.cProd, // código do item no xml
                Number(dadosDaCapa.cod_loja), // código da loja
                parametrosValidacao.validar_somente_pela_ref,
              );

              // Passo 3 - Validar itens para inserção na tabela
              const dadosParaFormatacao = {
                item: {
                  index: String(i + 1),
                  edicao: false,
                  erpCodProduto: productCodeAndGtin[0]
                    ? productCodeAndGtin[0].cod_produto
                    : '',
                  erpCodGtin: productCodeAndGtin[0]
                    ? productCodeAndGtin[0].cod_gtin
                    : '',
                  xmlCProd: item.cProd,
                  xmlCean: item.cEAN,
                  xmlCeanTrib: item.cEANTrib,
                  xProd: item.xProd,
                  xmlCfop: item.CFOP,
                  xmlUCom: item.uCom,
                  xmlUTrib: item.uTrib,
                  xmlpICMS:
                    item.imposto.ICMS?.[item.imposto.icmsKey]?.pICMS || 0,
                  xmlpRedBCST:
                    item.imposto.ICMS?.[item.imposto.icmsKey]?.pRedBCST || 0,
                  xmlpMVAST: item.imposto.ICMS?.[item.imposto.icmsKey]?.pMVAST,
                  xmlpST: item.imposto.ICMS.ICMS60?.pST,
                  xmlCEST: item.CEST,
                  xmlNCM: item.NCM,
                  xmlIcmsCST:
                    item.imposto.ICMS.CST ||
                    item.imposto.ICMS?.[item.imposto.icmsKey]?.CST,
                  xmlpRedBC:
                    item.imposto.ICMS?.[item.imposto.icmsKey]?.pRedBC || 0,
                  xmlpICMSST:
                    item.imposto.ICMS?.[item.imposto.icmsKey]?.pICMSST || 0,
                  xmlvProd: item.vProd || 0,
                  xmlvBC: item.imposto.ICMS?.[item.imposto.icmsKey]?.vBC || 0,
                  xmlCSOSN: item.imposto.ICMS.ICMSSN102?.CSOSN || 0,
                  xmlvICMSST:
                    item.imposto.ICMS?.[item.imposto.icmsKey]?.vICMSST || 0,
                  des_unidade_compra: item.uCom,
                  perfil: String(parametrosValidacao.cod_perfil),
                },
                fornecedor: {
                  uf: dadosErpDoFornecedor.des_uf.value,
                  cod_fornecedor: Number(
                    dadosErpDoFornecedor.cod_fornecedor.value,
                  ),
                },
                loja: {
                  uf: dadosDaCapa.des_uf,
                  cod_loja: Number(dadosDaCapa.cod_loja),
                },
                parametrosValidacao,
                dadosDaCapa,
                dadosErpDoFornecedor,
                setStatus: showLog ? setStatusValidacao : undefined,
              };

              const itensFormatados = await formataItensParaTabela(
                dadosParaFormatacao,
              );
              const produtoNaoEncontrado =
                itensFormatados.statusDaValidacao.find(
                  (status) => status.codStatus === 1,
                );

              let itensValidados = itensFormatados;
              if (!produtoNaoEncontrado) {
                itensValidados = await validaItensDoXML(
                  itensFormatados,
                  dadosDaCapa,
                  parametrosValidacao,
                  dadosErpDoFornecedor,
                  false,
                  nfValidada,
                );
              }
              const qtdStatusBloq = itensValidados.statusDaValidacao.filter(
                (status) => status.restricao === 'BLOQ',
              );
              const qtdStatusNe = itensValidados.statusDaValidacao.filter(
                (status) => status.restricao === 'NE',
              );
              const qtdStatusMix = itensValidados.statusDaValidacao.filter(
                (status) => status.restricao === 'MIX ',
              );
              const qtdStatusInf = itensValidados.statusDaValidacao.filter(
                (status) => status.restricao === 'INF',
              );
              itensValidados.qtdStatusBloq = Number(qtdStatusBloq?.length);
              itensValidados.qtdStatusNe = Number(qtdStatusNe?.length);
              itensValidados.qtdStatusMix = Number(qtdStatusMix?.length);
              itensValidados.qtdStatusInf = Number(qtdStatusInf?.length);

              newItem.push(itensValidados);

              if (itensValidados.statusDaValidacao.length > 0 && showLog) {
                setStatusValidacao((prev) => [
                  ...prev,
                  `- Foram encontradas ${itensValidados.statusDaValidacao.length} pendências. Utilize a tabela de itens para verifica-las.`,
                  ' ',
                ]);
              } else if (showLog) {
                setStatusValidacao((prev) => [
                  ...prev,
                  '- Item validado com sucesso.',
                  ' ',
                ]);
              }
            },
          );

          await Promise.all(itensForPromise);

          newItem.sort((a: ProductDataToTable, b: ProductDataToTable) => {
            if (Number(a.index) < Number(b.index)) {
              return -1;
            }
            if (Number(a.index) > Number(b.index)) {
              return 1;
            }
            return 0;
          });

          const itens = corrigirArrayDeObjetos(newItem);

          await alteraStatusdoXml({
            codXml: Number(dadosDaCapa.cod_xml_cap),
            tipoIcmsSt: parametrosValidacao.icms_st,
            TipoIcmsOp: parametrosValidacao.icms_op,
            codPerfil: Number(parametrosValidacao.cod_perfil),
            dadosErpDoFornecedor,
            itensDaTabela: itens,
          });

          setItensDaTabela(itens);
          setShowXMLData('1');
          if (!showLog) {
            setShowParams('1');
          }
          setShowTable('0');
          setShowCapa('0');
        }
      } catch (error) {
        console.error(error);
      }
    },
    [
      dadosDoXML,
      setItensDaTabela,
      setShowXMLData,
      setShowTable,
      setShowCapa,
      setStatusValidacao,
      dadosErpDoFornecedor,
      dadosDaCapa,
      parametrosValidacao,
      setShowParams,
    ],
  );

  const validaItensDaCapa = useCallback(() => {
    const inputs = {
      des_fornecedor:
        dadosErpDoFornecedor.des_fornecedor.value !==
        dadosDoXML?.fornecedor.xNome,
      num_rg_ie:
        dadosErpDoFornecedor.num_rg_ie.value !== dadosDoXML?.fornecedor.IE,
      num_cpf_cnpj:
        dadosErpDoFornecedor.num_cpf_cnpj.value !== dadosDoXML?.fornecedor.CNPJ,
      des_logradouro:
        dadosErpDoFornecedor.des_logradouro.value !==
        dadosDoXML?.fornecedor.enderEmit.xLgr,
      num_endereco:
        dadosErpDoFornecedor.num_endereco.value !==
        dadosDoXML?.fornecedor.enderEmit.nro,
      des_bairro:
        dadosErpDoFornecedor.des_bairro.value !==
        dadosDoXML?.fornecedor.enderEmit.xBairro,
      num_cep:
        dadosErpDoFornecedor.num_cep.value !==
        dadosDoXML?.fornecedor.enderEmit.CEP,
      des_cidade:
        dadosErpDoFornecedor.des_cidade.value !==
        dadosDoXML?.fornecedor.enderEmit.xMun,
      des_uf:
        dadosErpDoFornecedor.des_uf.value !==
        dadosDoXML?.fornecedor.enderEmit.UF,
      num_telefone:
        dadosErpDoFornecedor.num_telefone.value !==
        dadosDoXML?.fornecedor.enderEmit.fone,
    };
    setDadosErpDoFornecedor({
      ...dadosErpDoFornecedor,
      num_cpf_cnpj: {
        ...dadosErpDoFornecedor.num_cpf_cnpj,
        invalid: inputs.num_cpf_cnpj,
      },
      num_rg_ie: {
        ...dadosErpDoFornecedor.num_rg_ie,
        invalid: inputs.num_rg_ie,
      },
      des_fornecedor: {
        ...dadosErpDoFornecedor.des_fornecedor,
        invalid: inputs.des_fornecedor,
      },
      apelido_fantasia: {
        ...dadosErpDoFornecedor.apelido_fantasia,
        invalid: false,
      },
      des_uf: {
        ...dadosErpDoFornecedor.des_uf,
        invalid: inputs.des_uf,
      },
      des_logradouro: {
        ...dadosErpDoFornecedor.des_logradouro,
        invalid: inputs.des_logradouro,
      },
      num_endereco: {
        ...dadosErpDoFornecedor.num_endereco,
        invalid: inputs.num_endereco,
      },
      des_bairro: {
        ...dadosErpDoFornecedor.des_bairro,
        invalid: inputs.des_bairro,
      },
      num_cep: {
        ...dadosErpDoFornecedor.num_cep,
        invalid: inputs.num_cep,
      },
      num_telefone: {
        ...dadosErpDoFornecedor.num_telefone,
        invalid: inputs.num_telefone,
      },
      des_cidade: {
        ...dadosErpDoFornecedor.des_cidade,
        invalid: inputs.des_cidade,
      },
    });
  }, [
    dadosErpDoFornecedor,
    dadosDoXML?.fornecedor.CNPJ,
    dadosDoXML?.fornecedor.IE,
    dadosDoXML?.fornecedor.enderEmit.CEP,
    dadosDoXML?.fornecedor.enderEmit.UF,
    dadosDoXML?.fornecedor.enderEmit.fone,
    dadosDoXML?.fornecedor.enderEmit.nro,
    dadosDoXML?.fornecedor.enderEmit.xBairro,
    dadosDoXML?.fornecedor.enderEmit.xLgr,
    dadosDoXML?.fornecedor.enderEmit.xMun,
    dadosDoXML?.fornecedor.xNome,
    setDadosErpDoFornecedor,
  ]);

  const validateXML = useCallback(
    async (showLog?: boolean) => {
      if (getValues('perfil') === '' || getValues('perfil') === undefined) {
        toast.warning('Perfil deve ser selecionado.');
        return;
      }
      /**
       * Valida Fornecedor
       * TODO: Adicionar validação de UF
       */
      if (showLog) {
        setStatusValidacao((prev) => [
          ...prev,
          '[FORNECEDOR]',
          '- Validando Fornecedor',
        ]);
      }
      const isValidFornecedor = await isFornNotRegisteredOrInactive({
        cod_pessoa: Number(dadosDaCapa.cod_pessoa),
        flg_fornecedor: dadosDaCapa.flg_fornecedor,
        flg_inativo: dadosDaCapa.flg_inativo,
        des_uf: dadosDaCapa.des_uf,
        setStatus: showLog ? setStatusValidacao : undefined,
      });
      if (isValidFornecedor.invalid) {
        toast.error(isValidFornecedor.message);
        setIsValidXML(false);
        setLoading(false);
        if (showLog) {
          setStatusValidacao((prev) => [
            ...prev,
            '[Falha] - Fornecedor inválido.',
          ]);
        }
        return;
      }
      if (showLog) {
        setStatusValidacao((prev) => [
          ...prev,
          '- Fornecedor Validado com sucesso.',
          ' ',
        ]);
      }

      setLoading(true);

      /**
       * Valida Status NF
       */
      const isNFCanceled = await isNotaFiscalCanceled(
        dadosDaCapa.tipo_status_sefaz,
      );
      if (isNFCanceled.invalid) {
        toast.error(isNFCanceled.message);
        setIsValidXML(false);
        setLoading(false);
        return;
      }

      /**
       * Valida Manifesto
       */
      if (showLog) {
        setStatusValidacao((prev) => [
          ...prev,
          '[STATUS MANIFESTO]',
          '- Validando Status Manifesto',
        ]);
      }
      const isValidStatusManifest = await isTipoStatusManifestoNotValid(
        Number(dadosDaCapa.tipo_status_manifesto),
        showLog ? setStatusValidacao : undefined,
      );
      if (isValidStatusManifest.invalid) {
        toast.warning(isValidStatusManifest.message);
        setIsValidXML(false);
        setLoading(false);
        if (showLog) {
          setStatusValidacao((prev) => [
            ...prev,
            '[Falha] - Status Manifesto inválido.',
          ]);
        }
        return;
      }
      if (showLog) {
        setStatusValidacao((prev) => [
          ...prev,
          '- Status Manifesto Validado com Sucesso',
          ' ',
        ]);
      }

      /**
       * Valida NF Processada
       */
      if (showLog) {
        setStatusValidacao((prev) => [
          ...prev,
          '[NF Processada]',
          '- Validando NF Processada',
        ]);
      }

      const isNFProcessed = await isNFProcessede(
        dadosDaCapa.tipo_status,
        showLog ? setStatusValidacao : undefined,
      );
      if (isNFProcessed.invalid) {
        const continua = await MySwal.fire({
          title: isNFProcessed.title,
          text: isNFProcessed.message,
          showCancelButton: true,
          confirmButtonColor: '#8850BF',
          cancelButtonColor: '#DE350B',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            return true;
          }
          return false;
        });
        if (!continua) {
          toast.warn('Operação cancelada.');
          setIsValidXML(false);
          if (showLog) {
            setStatusValidacao((prev) => [...prev, '- Operação cancelada']);
          }
          setLoading(false);
          return;
        }
      }
      if (showLog) {
        setStatusValidacao((prev) => [
          ...prev,
          '- NF Processada Validado com Sucesso',
          '',
        ]);
      }

      /**
       * Valida NF Validada
       */
      if (showLog) {
        setStatusValidacao((prev) => [
          ...prev,
          '[NF Validada]',
          '- Validando NF Validada',
        ]);
      }
      const isNFConfirmedAndValidated = await isNFValidated(
        dadosDaCapa.tipo_status,
        showLog ? setStatusValidacao : undefined,
      );
      if (isNFConfirmedAndValidated.invalid) {
        const continua = await MySwal.fire({
          title: isNFConfirmedAndValidated.title,
          text: isNFConfirmedAndValidated.message,
          showCancelButton: true,
          confirmButtonColor: '#8850BF',
          cancelButtonColor: '#DE350B',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            return true;
          }
          return false;
        });
        if (!continua) {
          toast.warn('Operação cancelada.');
          setIsValidXML(false);
          setLoading(false);
          if (showLog) {
            setStatusValidacao((prev) => [...prev, '- Operação cancelada']);
          }
          return;
        }
        // Alterar status nf_xml.flg_validada_confirmada para false
        const { data: reponseData } = await api.put('/valida-nf', {
          cod_xml: dadosDaCapa.cod_xml_cap,
          isValidated: false,
        });
        if (!reponseData.success && reponseData.message) {
          toast.error(String(reponseData.message));
          setIsValidXML(false);
          if (showLog) {
            setStatusValidacao((prev) => [
              ...prev,
              '[Falha] - Falha ao alterar status da NF.',
            ]);
          }

          return;
        }
        if (showLog) {
          setStatusValidacao((prev) => [
            ...prev,
            '- Status da NF Alterado com sucesso.',
            ' ',
          ]);
        }

        setDadosDaCapa({
          ...dadosDaCapa,
          tipo_status: 0,
        });
        isNFConfirmedAndValidated.invalid = false;
      }
      setItensDaTabela([]);
      if (showLog) {
        setStatusValidacao((prev) => [
          ...prev,
          '- NF Validada com Sucesso',
          ' ',
        ]);
      }
      if (isNFConfirmedAndValidated.invalid === false) {
        await itemsValidation(showLog, isNFConfirmedAndValidated.invalid);
      }

      validaItensDaCapa();
      setIsValidXML(true);
      setShowTotais('0');
      setCamposAlterados([]);
      onSetToastShown(false);
      onSetCounterValida();

      if (showLog) {
        setStatusValidacao((prev) => [...prev, '[VALIDAÇÃO CONCLUÍDA]', ' ']);
      }
      setLoading(false);
    },
    [
      dadosDaCapa,
      getValues,
      itemsValidation,
      setCamposAlterados,
      setDadosDaCapa,
      setIsValidXML,
      setItensDaTabela,
      setLoading,
      setShowTotais,
      setStatusValidacao,
      validaItensDaCapa,
      onSetToastShown,
      onSetCounterValida,
    ],
  );

  useEffect(() => {
    (async () => {
      const { value } = getValues('tipo');

      let flg_interestadual = false;
      if (dadosDaCapa && dadosErpDoFornecedor?.cod_fornecedor) {
        flg_interestadual =
          dadosDaCapa.des_uf !== dadosErpDoFornecedor.des_uf.value;
      }

      const { data } = await api.get('/validador-perfil', {
        params: {
          tipo_nf: value,
          flg_interestadual,
          cod_pessoa: dadosDaCapa.cod_pessoa,
        },
      });
      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data.map((perfil: PerfilData) => ({
            label: perfil.des_perfil,
            value: perfil.cod_perfil,
          }));

          setPerfis(options);
        }
      }
    })();
  }, [getValues, setPerfis, refetchPerfil, dadosDaCapa, dadosErpDoFornecedor]);

  const textAreaRef = useRef<any>();
  useLayoutEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [statusValidacao]);

  return (
    <Accordion defaultActiveKey="0">
      <div className="row accordion-row">
        <div className="col-12" style={{ paddingRight: '0px' }}>
          <CustomSeparator
            label="Parâmetros de Validação"
            eventKey={showParams}
            onChange={(newEventKey) => {
              setShowParams(newEventKey);
            }}
          />
        </div>
      </div>
      <div id="accordionParams" className="accordion">
        <Accordion.Collapse
          as={Button}
          eventKey={showParams}
          className="row params-data"
        >
          <>
            <div className="col-sm-12 col-md-2">
              <InputSelect
                label="Tipo"
                maxLength={50}
                placeholder="Selecione..."
                name="tipo"
                register={register}
                isError={!!formState.errors.tipo}
                control={control}
                options={tipoDoXML}
                changeSelected={(selected) => {
                  setValue('tipo', selected);
                  setRefetchPerfil(!refetchPerfil);
                }}
              />
            </div>
            <div className="col-sm-12 col-md-10">
              <InputSelect
                label="Perfil"
                maxLength={50}
                placeholder="Selecione..."
                name="perfil"
                register={register}
                isError={!!formState.errors.perfil}
                control={control}
                options={perfis}
                changeSelected={(selected) => {
                  setParametrosValidacao((prev) => ({
                    ...prev,
                    cod_perfil: String(selected.value),
                  }));
                  setValue('perfil', selected);
                }}
              />
            </div>
            <SectionParams>
              <section className="toggle-content-considerar-impostos">
                <div className="span-info">
                  <span>Considerar impostos:</span>
                </div>
                <div>
                  <ToggleDefault
                    labelText="ICMS ST?"
                    setChecked={parametrosValidacao.icms_st}
                    onSwitch={() => {
                      setParametrosValidacao((prev) => ({
                        ...prev,
                        icms_st: !parametrosValidacao.icms_st,
                      }));
                      setValue('icms_st', !parametrosValidacao.icms_st);
                    }}
                    inLine
                    fontSize="0.8971428571428571em"
                  />
                </div>
                <div>
                  <ToggleDefault
                    labelText="ICMS OP?"
                    setChecked={parametrosValidacao.icms_op}
                    onSwitch={() => {
                      setParametrosValidacao((prev) => ({
                        ...prev,
                        icms_op: !parametrosValidacao.icms_op,
                      }));
                      setValue('icms_op', !parametrosValidacao.icms_op);
                    }}
                    inLine
                    fontSize="0.8971428571428571em"
                  />
                </div>
              </section>
              <section className="toggle-content">
                <div>
                  <ToggleDefault
                    labelText="Validar Somente pela Ref?"
                    setChecked={parametrosValidacao.validar_somente_pela_ref}
                    onSwitch={() => {
                      setParametrosValidacao((prev) => ({
                        ...prev,
                        validar_somente_pela_ref:
                          !parametrosValidacao.validar_somente_pela_ref,
                      }));
                      setValue(
                        'validar_somente_pela_ref',
                        !parametrosValidacao.validar_somente_pela_ref,
                      );
                    }}
                    inLine
                    fontSize="0.8971428571428571em"
                  />
                </div>
                <div>
                  <ToggleDefault
                    labelText="Atualiza Fornecedor Preferencial?"
                    setChecked={parametrosValidacao.atualiza_forn_preferencial}
                    onSwitch={() => {
                      setParametrosValidacao((prev) => ({
                        ...prev,
                        atualiza_forn_preferencial:
                          !parametrosValidacao.atualiza_forn_preferencial,
                      }));
                      setValue(
                        'atualiza_forn_preferencial',
                        !parametrosValidacao.atualiza_forn_preferencial,
                      );
                    }}
                    inLine
                    fontSize="0.8971428571428571em"
                  />
                </div>
              </section>
              <div className="btn-validar">
                {user?.flg_superadmin ? (
                  <SplitButton
                    key="Primary"
                    id="dropdown-split-variants-teste"
                    variant="primary"
                    disabled={loading || hasFornecedor === false}
                    onClick={() => {
                      if (loading === false && hasFornecedor) {
                        validateXML();
                      }
                    }}
                    toggleLabel=""
                    title={
                      loading ? (
                        <Spinner animation="border" variant="light" size="sm" />
                      ) : (
                        <>
                          <BsLightningFill fontSize="20px" color="#fff" />
                          VALIDAR XML
                        </>
                      )
                    }
                  >
                    <Dropdown.Item
                      className="dropdown-btn"
                      eventKey="1"
                      disabled={loading || hasFornecedor === false}
                      onClick={() => {
                        if (loading === false && hasFornecedor) {
                          setShowStatusValidacao('0');
                          validateXML(true);
                        }
                      }}
                    >
                      Validar e Visualizar Log
                    </Dropdown.Item>
                  </SplitButton>
                ) : (
                  <button
                    type="button"
                    disabled={loading || hasFornecedor === false}
                    onClick={() => {
                      if (loading === false && hasFornecedor) {
                        validateXML();
                      }
                    }}
                    style={{
                      borderRadius: '3px',
                    }}
                  >
                    {loading ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      <>
                        <BsLightningFill fontSize="20px" color="#fff" />
                        VALIDAR XML
                      </>
                    )}
                  </button>
                )}
              </div>
            </SectionParams>
            <Accordion.Collapse
              as={Button}
              eventKey={showStatusValidacao}
              className="log-visialization"
            >
              <>
                <div className="col-sm-12">
                  <textarea
                    ref={textAreaRef}
                    value={statusValidacao.join('\n')}
                    style={{
                      width: '100%',
                      height: '300px',
                      minHeight: '300px',
                      backgroundColor: '#3f3f3f',
                      color: '#00bbc2',
                    }}
                  />
                </div>
                <div className="col-sm-12 btn-validar">
                  <button
                    type="button"
                    onClick={() => {
                      setStatusValidacao([]);
                      setShowStatusValidacao('1');
                    }}
                  >
                    Limpar e fechar
                  </button>
                </div>
              </>
            </Accordion.Collapse>
          </>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
};
