import React from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { CustomSeparator } from '../../Components';
import { useValidadorXML } from '../../ValidadorContext';
import { moneyFormat } from '~/utils/functions';

export const TotaisDoXML: React.FC = () => {
  const { showTotais, setShowTotais, dadosDoXML, dadosDaCapa } =
    useValidadorXML();
  return (
    <Accordion defaultActiveKey="0">
      <div className="row accordion-row">
        <div className="col-12" style={{ paddingRight: '0px' }}>
          <CustomSeparator
            label="Totais XML"
            eventKey={showTotais}
            onChange={(newEventKey) => {
              setShowTotais(newEventKey);
            }}
          />
        </div>
      </div>
      <div id="accordionTable" className="accordion">
        <Accordion.Collapse
          as={Button}
          eventKey={showTotais}
          className="row params-data"
        >
          <>
            <div className="col-sm-12 table-exibition">
              <table className="table">
                <thead>
                  <tr>
                    <th>BC ICMS</th>
                    <th>ICMS</th>
                    <th>BC ICMS ST</th>
                    <th>ICMS ST</th>
                    <th>Total Produtos</th>
                    <th>Val. Total NF</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{moneyFormat(dadosDoXML?.total.ICMSTot.vBC, 2)}</td>
                    <td>{moneyFormat(dadosDoXML?.total.ICMSTot.vICMS, 2)}</td>
                    <td>{moneyFormat(dadosDoXML?.total.ICMSTot.vBCST, 2)}</td>
                    <td>{moneyFormat(dadosDoXML?.total.ICMSTot.vST, 2)}</td>
                    <td>{moneyFormat(dadosDoXML?.total.ICMSTot.vProd, 2)}</td>
                    <td>{moneyFormat(String(dadosDaCapa.val_total_nfe), 2)}</td>
                  </tr>
                </tbody>
              </table>
              <table className="table">
                <thead>
                  <tr>
                    <th>Frete</th>
                    <th>IPI</th>
                    <th>Desconto</th>
                    <th>Desp. Acess</th>
                    <th>Val. Seguro</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{moneyFormat(dadosDoXML?.total.ICMSTot.vFrete, 2)}</td>
                    <td>{moneyFormat(dadosDoXML?.total.ICMSTot.vIPI, 2)}</td>
                    <td>{moneyFormat(dadosDoXML?.total.ICMSTot.vDesc, 2)}</td>
                    <td>{moneyFormat(dadosDoXML?.total.ICMSTot.vOutro, 2)}</td>
                    <td>{moneyFormat(dadosDoXML?.total.ICMSTot.vSeg, 2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
};
